/* General App Styling */
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;
}

.app {
  overflow-x: hidden;
}

/* Navbar Styling */
.navbar {
  position: fixed;
  top: 20px;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  align-items: center;
  gap: 5px;
  background-color: rgba(255, 255, 255, 0.8);
  backdrop-filter: blur(10px);
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  padding: 10px 40px;
  border-radius: 25px;
  z-index: 1000;
}

.navbar ul {
  display: flex;
  list-style: none;
  padding: 0;
  margin: 0;
  gap: 20px;
}

.navbar li {
  font-size: 16px;
  font-weight: 500;
  text-transform: none;
  cursor: pointer;
  transition: color 0.3s;
}

.navbar li:hover {
  color: #929292;
}

/* Dark Mode */
.light .navbar {
  background-color: rgba(255, 255, 255, 0.8);
}

.dark .navbar {
  background-color: rgba(0, 0, 0, 0.7);
  box-shadow: 2px 2px 6px rgba(255, 204, 0, 0.6);
}

.dark .navbar li {
  color: #f8e4ba;
}

.dark .navbar li:hover {
  color: #ffcc00;
}

/* Sections */
.section {
  min-height: auto;
  padding: 40px 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
}

/* Home page (Full height) */
#home.section {
  height: 100vh;
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

/* Alternate Background Colors for Light Mode */
.light .section:nth-child(odd) {
  background-color: #f8e4ba;
  color: #333;
}

.light .section:nth-child(even) {
  background-color: #fffff7;
  color: #333;
}

/* Dark Mode */
.dark .section:nth-child(odd) {
  background-color: #333;
  color: #f8e4ba;
}

.dark .section:nth-child(even) {
  background-color: #444;
  color: #f8e4ba;
}

/* Theme Switch Container */
.theme-switch-container {
  padding: 3px;
  border-radius: 20px;
  background-color: rgba(255, 255, 255, 0.8);
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.dark .theme-switch-container {
  background-color: rgba(0, 0, 0, 0.7);
}

/* Button controls (Resume + Theme) */
.controls {
  position: fixed;
  top: 20px;
  right: 20px;
  z-index: 1001;
  display: flex;
  align-items: center;
  gap: 15px;
}

.resume-button {
  display: inline-block;
  padding: 8px 15px;
  font-size: 14px;
  font-weight: bold;
  color: #fff;
  background-color: #000;
  text-decoration: none;
  border-radius: 50px;
  transition: background-color 0.3s;
}

.resume-button:hover {
  background-color: #005bb5;
}

.dark .resume-button {
  background-color: #ffcc00;
  color: #333;
}

.dark .resume-button:hover {
  background-color: #f8b700;
}

/* Responsive Design */
@media screen and (max-width: 768px) {
  .navbar {
    flex-direction: column;
    align-items: flex-start;
    gap: 0;
    padding: 18px 20px;
  }

  .navbar ul {
    display: none;
    /* Ensure it's hidden by default */
    flex-direction: column;
    width: auto;
    background-color: rgba(248, 228, 186, 1);
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    padding: 10px 0;
    position: absolute;
    top: 40px;
    left: -25px;
    z-index: 999;
    animation: slideIn 0.3s ease-in-out;
  }

  .navbar ul.show {
    display: flex;
    /* Only show when the class 'show' is applied */
  }

  .navbar li {
    padding: 10px 20px;
    width: 100%;
    text-align: left;
  }

  .dark .navbar li {
    color: #000000;
  }

  .dark .navbar {
    background-color: rgba(248, 228, 186, 1);
  }

  .menu-icon {
    display: block;
    cursor: pointer;
    z-index: 1001;
  }

  .hamburger {
    width: 25px;
    height: 3px;
    background-color: #333;
    position: relative;
    transition: all 0.3s ease-in-out;
  }

  .hamburger::before,
  .hamburger::after {
    content: "";
    position: absolute;
    width: 25px;
    height: 3px;
    background-color: #333;
    transition: all 0.3s ease-in-out;
  }

  .hamburger::before {
    top: -8px;
  }

  .hamburger::after {
    top: 8px;
  }

  .hamburger.open {
    background-color: transparent;
  }

  .hamburger.open::before {
    transform: rotate(45deg);
    top: 0;
  }

  .hamburger.open::after {
    transform: rotate(-45deg);
    top: 0;
  }

  /* Animation for slide-in effect */
  @keyframes slideIn {
    from {
      transform: translateY(-20px);
      opacity: 0;
    }

    to {
      transform: translateY(0);
      opacity: 1;
    }
  }
}