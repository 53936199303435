/* Light Mode Styling */
.experience-container {
    padding: 40px;
    background-color: #f8e4ba;
    /* Light beige */
    transition: background-color 0.3s ease;
}

.experience-title {
    text-align: center;
    font-size: 36px;
    font-weight: bold;
    margin-bottom: 20px;
}

.vertical-timeline-element-title {
    font-size: 20px;
    font-weight: bold;
}

.vertical-timeline-element-subtitle {
    font-size: 16px;
    font-weight: 500;
    color: #555;
}

.skills-tags {
    margin-top: 10px;
}

.tag {
    display: inline-block;
    padding: 5px 10px;
    margin: 5px;
    background-color: #ddd;
    color: #000;
    font-size: 14px;
    border-radius: 12px;
}

/* Dark Mode Styling */
.dark .experience-container {
    background-color: #333;
    /* Dark background for dark mode */
    color: #f8e4ba;
    /* Light text for dark mode */
}

.dark .tag {
    background-color: #444;
    /* Slightly lighter gray for tags in dark mode */
    color: #f8e4ba;
    /* Light text for tags */
}

.dark .vertical-timeline-element-title {
    color: #ffffff;
    /* Fully white for titles in dark mode */
}

.dark .vertical-timeline-element-subtitle {
    color: #cccccc;
    /* Lighter gray for subtitles in dark mode */
}

.dark .vertical-timeline-element-content {
    background-color: #444;
    /* Dark background for content cards */
    border: 1px solid #555;
    /* Subtle border for better visibility */
    box-shadow: 0px 4px 10px rgba(255, 204, 0, 0.3);
    /* Golden shadow for better theme alignment */
    border-radius: 8px;
    color: #f8e4ba;
    /* Text color consistent with the theme */
}

.dark .skills-tags .tag {
    background-color: #555;
    /* Darker gray for tags */
    color: #f8e4ba;
    /* Theme-consistent text color */
}


/* Custom CSS for fixing date spacing */
.vertical-timeline-element-date {
    margin-top: 0;
    margin-bottom: 10px;
    margin-left: -60px;
}

.vertical-timeline--two-columns .vertical-timeline-element:nth-child(even):not(.vertical-timeline-element--left) .vertical-timeline-element-content .vertical-timeline-element-date {
    left: auto;
    right: 130% !important;
    text-align: right;
}

@media screen and (max-width: 768px) {
    .vertical-timeline-element-date {
        margin-left: 0;
    }
}