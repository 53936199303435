.skills-title {
    font-size: 32px;
    font-weight: bold;
    margin-bottom: 30px;
    /* Light text */
}

/* Skills Grid Layout */
.skills-grid {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    justify-content: center;
}

/* Skill Card */
.skill-card {
    width: 120px;
    height: 100px;
    background-color: #2a2a2a;
    color: #f8e4ba;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 13px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    cursor: pointer;
}

.skill-card:hover {
    transform: scale(1.1);
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.2);
}

/* Skill Icon */
.skill-icon {
    font-size: 50px;
    margin-bottom: 0px;
    margin-top: 15px;
    color: #f8e4ba;
    /* Default light text */
}

/* Skill Name */
.skill-name {
    font-size: 11px;
    font-weight: normal;
    text-align: center;
}

/* Dark Mode */
.dark .skills-container {
    background-color: #333;
    color: #f8e4ba;
}

.dark .skill-card {
    background-color: #555;
    box-shadow: 0 4px 6px rgba(255, 204, 0, 0.6);
    /* Golden shadow */
}

.dark .skill-card:hover {
    background-color: #666;
    box-shadow: 0 6px 12px rgba(255, 204, 0, 0.8);
    /* Brighter golden shadow on hover */
}