.footer {
    background-color: #333;
    /* Dark background */
    color: #f8e4ba;
    /* Light text */
    text-align: center;
    padding: 20px 0;
    position: relative;
}

.footer-icons {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 20px;
    margin-bottom: 10px;
}

.footer-icon {
    font-size: 24px;
    color: #f8e4ba;
    /* Light icon color */
    transition: color 0.3s ease, transform 0.3s ease;
}

.footer-icon:hover {
    color: #ffcc00;
    /* Hover color */
    transform: scale(1.1);
    /* Slight zoom on hover */
}

.footer-text {
    font-size: 14px;
    font-weight: 500;
}