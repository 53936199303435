/* General Contact Styling */
.contact-container {
    padding: 40px;
    max-width: 600px;
    margin: 0 auto;
    text-align: center;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
    /* Default shadow for light mode */
    transition: box-shadow 0.3s ease;
}

.contact-title {
    font-size: 32px;
    font-weight: 600;
    margin-bottom: 10px;
    color: #333;
}

.contact-description {
    font-size: 16px;
    color: #555;
    margin-bottom: 30px;
}

/* Form Styling */
.contact-form {
    display: flex;
    flex-direction: column;
    gap: 20px;
    align-items: center;
}

.contact-form div {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
}

.contact-form label {
    font-size: 14px;
    font-weight: 500;
    color: #6d6d6d;
    margin-bottom: 5px;
    text-transform: uppercase;
    letter-spacing: 1px;
}

.contact-form input,
.contact-form textarea {
    width: 90%;
    padding: 12px 15px;
    font-size: 16px;
    border: 1px solid #ddd;
    border-radius: 10px;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
    background-color: #fafafa;
    transition: border-color 0.3s, box-shadow 0.3s;
    font-family: inherit;
}

.contact-form input:focus,
.contact-form textarea:focus {
    border-color: #007aff;
    box-shadow: 0 0 8px rgba(0, 122, 255, 0.3);
    outline: none;
}

.contact-form textarea {
    resize: none;
    height: 120px;
}

.contact-form button {
    padding: 10px 20px;
    font-size: 16px;
    font-weight: 600;
    color: #fff;
    background-color: #000000;
    border: none;
    border-radius: 12px;
    cursor: pointer;
    transition: background-color 0.3s, box-shadow 0.3s;
    width: fit-content;
    align-self: center;
}

.contact-form button:hover {
    background-color: #0056b3;
    box-shadow: 0 4px 10px rgba(0, 122, 255, 0.3);
}

/* Dark Mode */
.dark .contact-container {
    background-color: #333;
    color: #f8e4ba;
    box-shadow: 0 8px 20px rgba(255, 204, 0, 0.4);
    /* Golden shadow for dark mode */
}

.dark .contact-title {
    color: #f8e4ba;
}

.dark .contact-description {
    color: #ddd;
}

.dark .contact-form input,
.dark .contact-form textarea {
    background-color: #444;
    border: 1px solid #666;
    color: #f8e4ba;
}

.dark .contact-form input::placeholder,
.dark .contact-form textarea::placeholder {
    color: #bbb;
}

.dark .contact-form input:focus,
.dark .contact-form textarea:focus {
    border-color: #ffcc00;
    box-shadow: 0 0 8px rgba(255, 204, 0, 0.4);
}

.dark .contact-form button {
    background-color: #ffcc00;
    color: #333;
}

.dark .contact-form button:hover {
    background-color: #f8b700;
    box-shadow: 0 4px 10px rgba(255, 204, 0, 0.4);
}

/* Popup Notification */
.popup {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background: rgba(0, 0, 0, 0.4);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

.popup-content {
    background: white;
    padding: 25px 30px;
    border-radius: 12px;
    text-align: center;
    box-shadow: 0 8px 20px rgba(0, 0, 0, 0.2);
    animation: fadeIn 0.3s ease-in-out;
}

.popup-content p {
    margin: 0 0 15px;
    font-size: 18px;
    font-weight: 500;
    color: #333;
}

.dark .popup-content {
    background-color: #444;
    color: #f8e4ba;
    box-shadow: 0 8px 20px rgba(255, 204, 0, 0.4);
    /* Golden shadow for popup in dark mode */
}

.close-button {
    padding: 10px 20px;
    background-color: #007aff;
    color: white;
    border: none;
    border-radius: 10px;
    cursor: pointer;
    font-size: 16px;
    font-weight: 600;
    transition: background-color 0.3s, box-shadow 0.3s;
}

.close-button:hover {
    background-color: #0056b3;
    box-shadow: 0 4px 10px rgba(0, 122, 255, 0.3);
}

.dark .close-button {
    background-color: #ffcc00;
    color: #333;
}

.dark .close-button:hover {
    background-color: #f8b700;
    box-shadow: 0 4px 10px rgba(255, 204, 0, 0.3);
}

@keyframes fadeIn {
    from {
        opacity: 0;
        transform: scale(0.9);
    }

    to {
        opacity: 1;
        transform: scale(1);
    }
}